// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_gYCpI{display:flex;flex-direction:column;margin-bottom:1px;overflow:visible}.widgets_gkIoC{display:flex;flex-direction:row;flex-wrap:wrap}.container_gYCpI:last-child{margin-bottom:0}.widget-layout-row-dynamic .widget-widget-blank{height:auto;width:25%}.widget-layout-row-dynamic-paddingDouble .widget-widget-blank{height:auto;width:calc(25% - 1rem)}.widget-layout-row-dynamic .widget-widget-blank:not(:last-child){margin-right:1px}.widget-layout-row-dynamic-paddingDouble .widget-widget-blank:not(:last-child){margin-bottom:.25rem;margin-right:.25rem}.widget-layout-row-dynamic .widget-widget-blank:nth-child(4n){margin-right:0;width:25%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_gYCpI",
	"widgets": "widgets_gkIoC"
};
module.exports = ___CSS_LOADER_EXPORT___;
